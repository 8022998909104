import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentVideo({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentVideo" className="section bg-gradient-to-b from-site-light to-white relative z-10">
      <div className="container md:grid flex flex-col grid-cols-12 gap-8 sm:gap-20">
        <div className={clsx('lg:col-span-7 col-span-6 relative', !fields.swap && 'order-2')}>
          <div className="absolute pointer-events-none -rotate-12 -left-[100%] sm:-top-[50%] -top-[30%] w-[300%] sm:h-[169%] h-[150%] bg-gradient-radial from-site-base-400/50 via-transparent" />
          <div className="w-full aspect-video overflow-hidden">
            <iframe
              src={fields.videoUrl}
              className={clsx('h-[calc(100%+theme(space.2))] -mt-1 relative w-full', fields.swap ? 'mr-auto' : 'ml-auto')}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Video"
            />
          </div>
        </div>
        <div className="lg:col-span-5 col-span-6 relative">
          {fields.description && (
            <Content className="content xl:children-strong:text-6xl lg:children-strong:text-5xl md:children-strong:text-4xl children-strong:text-3xl   xl:children-h2:text-4xl md:children-h2:text-3xl children-h2:text-2xl children-strong:font-outline-site-base">
              {fields?.description}
            </Content>
          )}

          {fields.links && (
            <div className="flex flex-wrap items-center gap-2 mt-8">
              {fields.links.map((link, index) => (
                <Button className="odd:btn-light even:btn-default" key={index} to={link?.link?.url || '/'}>
                  {link?.link?.title}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
