import type { ComponentPropsWithoutRef } from 'react'
import { LosseContent, losseContentToReact } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import clsx from 'clsx'

export default function Content({ ...rest }: ComponentPropsWithoutRef<'div'>) {
  return (
    <LosseContent
      {...rest}
      className={clsx(rest.className, 'content max-w-[75ch]')}
      replace={(domNode: any) => {
        const { name, attribs, children } = domNode

        if (name === 'a') {
          const attributes = { ...attribs }

          attributes.className = attributes.class
          delete attributes.class

          if (attribs.class === 'button') {
            return (
              <Button to={attributes.href} {...attributes}>
                {losseContentToReact(children)}
              </Button>
            )
          }
        }

        return null
      }}
    >
      {rest.children}
    </LosseContent>
  )
}
